import React, { useState } from 'react';

import parse from 'html-react-parser';
import { getMessages } from '../../locales';
import FormBox from './FormBox';

import optFormSrc from '../../assets/customer-consent/text-message.svg';
import Form from './Form';
import SuccessScreen from './SuccessScreen';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import DotIcon from '../Icons/DotIcon';
import httpClient from '../../utils/httpClient';

import ReactModal from 'react-modal';
import Button from '../common/Button/Button';
import CloseIcon from '../Icons/CloseIcon';

const FormSelect = ({ selectedForm, setFormCallback, setStep }) => {
  const messages = getMessages();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomDot = ({ onClick, active }) => {
    return (
      <li className="custom-dots" onClick={() => onClick()}>
        <DotIcon active={active} />
      </li>
    );
  };

  const acceptTermsAndConditions = (checkboxValue) => {
    setInputChecked(checkboxValue);
    setModalOpen(false);
  }

  const submitCallback = (formData) => {
    const formattedData = {
      account_id: formData.referenceNumber,
      name: formData.name,
      email: formData.email,
      phone_number: formData.phoneNumber.replace(/\D/g, ''),
      phone_number_confirmed: formData.confirmPhoneNumber.replace(/\D/g, ''),
      zip_code: formData.zipCode,
      opt_in: selectedForm === 'subscribe'
    };

    httpClient.post('/opt-in-out-sms', formattedData).then(() => setStep('success')).catch((err) => setStep('failure'));
  }

  return (
    <>
      <div className='container'>
        <div className='row no-gutters'>
          <div className='col-md-12 col-lg-5'>
            <h2 className='title-lg'>{messages['optForm.titles'][selectedForm].title}</h2>
            <p className='box-subtitle'>{messages['optForm.titles'][selectedForm].description}</p>
            <img src={optFormSrc} className='hide-md-down' alt="SMS Preference" />
          </div>
          <div className='col-12 col-lg-7'>
            {selectedForm === 'default' && 
              <>
                <div className='row no-gutters boxes-row hide-sm'>
                  {messages['optForm.boxes'].map((box, index) => (
                    <FormBox
                      box={box}
                      boxSelection={index === 0 ? 'subscribe': 'unsubscribe'}
                      key={index}
                      onClickCallback={setFormCallback}
                      className='opt-box col'
                    />
                  ))}
                </div>
                <Carousel
                  partialVisible
                  responsive={responsive}
                  arrows={false}
                  showDots
                  customDot={<CustomDot />}
                >
                  {messages['optForm.boxes'].map((box, index) => (
                    <FormBox box={box} key={index} onClickCallback={setFormCallback} className='opt-box col'
                    />
                  ))}
                </Carousel>
              </>
            }
            {selectedForm !== 'default' && <>
              <Form selectedType={selectedForm} disabled={!inputChecked} submitCallback={(data) => submitCallback(data)} />
              <div className='disclaimer'>
                <input type="checkbox" checked={inputChecked} onClick={() => setModalOpen(true)} id="disclaimer" name="disclaimer" />
                <p className='footer-text m-0'>
                  {parse(messages['optForm.disclaimer'])}
                </p>
              </div>
            </>}
            <p className='footer-text large'>{messages['home.disclaimer']}</p>
            <p className='footer-text'>{messages['footer.copyright']}</p>
          </div>
        </div>
      </div>
      <ReactModal
        overlayClassName="menu-overlay"
        className="menu-content terms-and-conditions"
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <div className='container'>
          <div className="row no-gutters">
            <div className="col-lg-12">
              <div className="row no-gutters align-items-center">
                <div className="col-10">
                  <h3 className='page-title'>{messages['termsAndConditions.title']}</h3>
                </div>
                <div className="col-2 text-end">
                  <Button ariaLabel="Close Menu" onClick={() => acceptTermsAndConditions(false)}>
                    <CloseIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <p>{parse(messages['termsAndConditions'])}</p>
          <Button
            buttonStyle="redesigned-primary"
            size="medium"
            wide
            onClick={() => acceptTermsAndConditions(true)}
          >
            {messages['termsAndConditions.button']}
          </Button>
        </div>
      </ReactModal>
    </>
  )
};

export default FormSelect;
