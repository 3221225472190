export const formValidations = {
  email: {
    required: 'You must provide an email address',
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'You must provide a valid email address',
    },
  },
  name: { required: 'You must provide a name' },
  referenceNumber: { required: 'You must provide a reference number' },
  phoneNumber: { required: 'You must provide a phone number' },
  confirmPhoneNumber: { required: 'You must confirm phone number' },
  zipCode: { required: 'You must provide a zip code' }
};
