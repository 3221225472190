import React from 'react';

import { getMessages } from '../../locales';

import failureSrc from '../../assets/customer-consent/failure.svg';

import parse from 'html-react-parser';
import Button from '../common/Button/Button';

const FailureScreen = () => {
  const messages = getMessages();

  return (
    <div className='container'>
      <div className='row no-gutters align-items-center failure-screen'>
        <div className='col-lg-5 col-md-12'>
          <img src={failureSrc} alt={messages['optForm.failure'].title} />
        </div>
        <div className='col-lg-7 col-md-12'>
          <h2 className='title-lg'>{messages['optForm.failure'].title}</h2>
          <p className='box-subtitle'>{parse(messages['optForm.failure'].subtitle)}</p>
          <Button
            linkTo="/"
            className='success-button'
            buttonStyle='redesigned-primary'
            title={messages['contactUs.success.button']}
          />
          <p className='footer-text large'>{messages['footer.knowYourRights']}</p>
          <p className='footer-text'>{messages['footer.copyright']}</p>
        </div>
      </div>
    </div>
  )
};

export default FailureScreen;
