import React from 'react';
import Button from '../common/Button/Button';
import parse from 'html-react-parser';

const FormBox = ({ box, className, onClickCallback, boxSelection }) => (
  <div className={className}>
    <span>{box.option}</span>
    <h5>{box.title}</h5>
    <p className='m-0'>{box.description}</p>
    <Button
      onClick={() => onClickCallback(boxSelection)}
      buttonStyle='redesigned-primary'
    >
      {box.button}
    </Button>
    <p className='m-0'>{parse(box.termsAndConditions)}</p>
  </div>
);

export default FormBox;
