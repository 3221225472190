import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';

import { placeholder, white, inputText } from '../../styles/base/_variables.scss';
import { getMessages } from '../../locales';
import { formValidations } from './validations';
import Button from '../common/Button/Button';

const Form = ({ selectedType, submitCallback, disabled }) => {
  const FormTextField = withStyles({
    root: {
      marginTop: '20px',
      marginBottom: '40px',
      '& input': {
        fontSize: 16,
        color: inputText,
        '&::placeholder': {
          fontSize: 16,
          color: placeholder,
          backgroundColor: white,
        },
      },
      '& textarea': {
        fontSize: 16,
        color: inputText,
        '&::placeholder': {
          fontSize: 16,
          color: placeholder,
          backgroundColor: white,
        },
      },
      '& .MuiInput-underline:after, & .MuiInput-underline:before': {
        borderColor: '#E0E5EB',
      },
    },
  })(TextField);

  const {
    handleSubmit,
    control,
    errors: fieldsErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const messages = getMessages();

  const formatPhoneNumber = (value) => {
    const cleaned = `${value}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      const intlCode = match[1] ? `(${match[1]}` : '';
      const middle = match[2] ? `) ${match[2]}` : '';
      const last = match[3] ? `-${match[3]}` : '';

      return `${intlCode}${middle}${last}`;
    }

    return value;
  };

  return (
    <form noValidate onSubmit={handleSubmit(submitCallback)} className="opt-form">
      <div className="row">
        <div className="col-12 requiredField">
          {messages['contactUs.information']} <span>*</span>
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="name"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['optForm.fields'].name }}
                fullWidth
                name="name"
                id="name"
                autoComplete="family-name"
                placeholder={messages['optForm.fields'].name}
                error={!!fieldsErrors.name}
                helperText={fieldsErrors?.name?.message}
              />
            }
            control={control}
            rules={formValidations.name}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="email"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['optForm.fields'].email }}
                fullWidth
                required
                name="email"
                id="email"
                autoComplete="email"
                placeholder={messages['optForm.fields'].email}
                error={!!fieldsErrors.email}
                helperText={fieldsErrors?.email?.message}
              />
            }
            control={control}
            rules={formValidations.email}
          />
        </div>
        <div className="col-12 col-lg-6">
          <div>
            <Controller
              name="referenceNumber"
              as={
                <FormTextField
                  inputProps={{ 'aria-label': messages['optForm.fields'].referenceNumber }}
                  fullWidth
                  required
                  name="reference"
                  id="reference"
                  autoComplete='reference'
                  placeholder={messages['optForm.fields'].referenceNumber}
                  error={!!fieldsErrors.referenceNumber}
                  helperText={fieldsErrors?.referenceNumber?.message}
                />
              }
              control={control}
              rules={formValidations.referenceNumber}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="phoneNumber"
            render={({ onChange, value }) => (
              <FormTextField
                inputProps={{ 'aria-label': messages['optForm.fields'].phoneNumber, maxLength: 14 }}
                fullWidth
                required
                name="phone"
                id="phone"
                autoComplete="tel"
                value={formatPhoneNumber(value || '')}
                placeholder={messages['optForm.fields'].phoneNumber}
                onChange={(e) => onChange(formatPhoneNumber(e.target.value))}
                error={!!fieldsErrors.phoneNumber}
                helperText={fieldsErrors?.phoneNumber?.message}
              />
            )}
            control={control}
            rules={formValidations.phoneNumber}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="confirmPhoneNumber"
            render={({ onChange, value }) => (
              <FormTextField
                inputProps={{ 'aria-label': messages['optForm.fields'].confirmPhoneNumber, maxLength: 14 }}
                fullWidth
                required
                name="confirmPhone"
                id="confirmPhone"
                autoComplete="tel"
                value={formatPhoneNumber(value || '')}
                placeholder={messages['optForm.fields'].confirmPhoneNumber}
                onChange={(e) => onChange(formatPhoneNumber(e.target.value))}
                error={!!fieldsErrors.confirmPhoneNumber}
                helperText={fieldsErrors?.confirmPhoneNumber?.message}
              />
            )}
            control={control}
            rules={formValidations.confirmPhoneNumber}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="zipCode"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['optForm.fields'].zipCode }}
                fullWidth
                required
                name="zipCode"
                id="zipCode"
                placeholder={messages['optForm.fields'].zipCode}
                error={!!fieldsErrors.zipCode}
                helperText={fieldsErrors?.zipCode?.message}
              />
            }
            control={control}
            rules={formValidations.zipCode}
          />
        </div>
        <div className="col-xs col-md-4">
          <Button
            type="submit"
            buttonStyle="primary"
            size="medium"
            wide
            className="submitButton"
            isDisabled={disabled}
          >
            {messages['optForm.buttons'][selectedType]}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Form;