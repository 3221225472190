import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

httpClient.interceptors.request.use(config => {
  const newHeaders = {
    'x-api-key': process.env.GATSBY_API_KEY
  };

  config.headers = {
    ...config.headers,
    ...newHeaders
  };

  return config;
});

export default httpClient;
